import React from 'react'
import Navbar from '../components/Navbar'
import Contact from '../components/Contact'
import Footer from '../components/Footer'

const ContactR = () => {
  return (
    <>
         < Navbar />
         < Contact />
         < Footer />
    </>
  )
}

export default ContactR