import React from 'react'
import Navbar from '../components/Navbar'
import PreFor from '../components/PreFor'
import Formaion from '../components/Formaion'
import Footer from '../components/Footer'


const formationPage = () => {
  return (
    <>
      < Navbar />
      < PreFor />
      < Formaion />
      < Footer />
    </>
  )
}

export default formationPage
