import React from 'react'

import { Link } from 'react-router-dom'
import Va from '../assets/va.jpg'
import Vb  from '../assets/vb.jpg'
import Vc from '../assets/vc.jpg'
import Vd from '../assets/vd.jpg'
import Ve from '../assets/ve.jpg'

import './Present.css'


const Present = () => {
  return (
    <>
    <div className='hero'>
            <div className='container'>

                {/* Left Side */}
                <div className='left'>
                    
                    <h1 className='pilote'>Créer une SA en ligne</h1>
                    <p className='pilote'>offre une manière rapide et efficace d'établir une société anonyme. Grâce à des plateformes spécialisées,
                     les entrepreneurs peuvent remplir les formalités administratives, 
                        choisir leur structure organisationnelle et finaliser la création de leur entreprise en quelques étapes simples, tout en bénéficiant d'un processus transparent et sécurisé.</p>
                   
                    <div className='input-container'>
                        
                    <a href='/contact'> <Link to='/contact'><button className='btn'>Commencer</button></Link></a>
                    </div>
                </div>


                {/* Right Side */}
                <div className='right'>
                    <div className='img-container'>
                        <img src={Va} alt=''/>
                    </div>
                </div>
            </div>


            <div className='hero'>
            <div className='container'>

                {/* Left Side */}
                <div className='left'>
                    <p className='pilote'>Lancer et développer votre propre entreprise peut sembler un défi de taille</p>
                    <h1 className='pilote'>Créer une SUARL en ligne</h1>
                    <p className='pilote'>simplifie le processus de création d'une société unipersonnelle à responsabilité limitée. Grâce à des services dédiés,
                     les entrepreneurs peuvent facilement accomplir les formalités légales, établir leur structure juridique et finaliser la création de leur entreprise en quelques étapes,
                     offrant ainsi une solution rapide et efficace pour démarrer une activité commerciale.</p>
                   
                    <div className='input-container'>
                        
                    <a href='/contact'> <Link to='/contact'><button className='btn'>Commencer</button></Link></a>
                    </div>
                </div>


                {/* Right Side */}
                <div className='right'>
                    <div className='img-container'>
                        <img src={Vb} alt=''/>
                    </div>
                </div>
            </div>
        </div>
        <div className='hero'>
            <div className='container'>

                {/* Left Side */}
                <div className='left'>
                    
                    <h1 className='pilote'>La création d'une association en ligne</h1>
                    <p className='pilote'>offre une voie simple et accessible pour établir une entité à but non lucratif. Grâce à des plateformes spécialisées,
                     les fondateurs peuvent accomplir les formalités administratives,
                      définir les objectifs et les règles de fonctionnement de leur association, le tout de manière rapide et transparente,
                     facilitant ainsi le lancement de projets communautaires ou caritatifs.</p>
                   
                    <div className='input-container'>
                        
                    <a href='/contact'> <Link to='/contact'><button className='btn'>Commencer</button></Link></a>
                    </div>
                </div>


                {/* Right Side */}
                <div className='right'>
                    <div className='img-container'>
                        <img src={Vc} alt=''/>
                    </div>
                </div>
            </div>
        </div>
        <div className='hero'>
            <div className='container'>

                {/* Left Side */}
                <div className='left'>
                    
                    <h1 className='pilote'>Créer une SARL en ligne</h1>
                    <p className='pilote'>
                    simplifie le processus de constitution d'une société à responsabilité limitée. Par le biais de plateformes dédiées,
                     les entrepreneurs peuvent accomplir les formalités administratives, définir la structure organisationnelle de leur entreprise et finaliser rapidement la création de leur société,
                     offrant ainsi une solution efficace pour démarrer une activité commerciale avec un minimum de tracas.</p>
                   
                    <div className='input-container'>
                        
                    <a href='/contact'> <Link to='/contact'><button className='btn'>Commencer</button></Link></a>
                    </div>
                </div>


                {/* Right Side */}
                <div className='right'>
                    <div className='img-container'>
                        <img src={Vd} alt=''/>
                    </div>
                </div>
            </div>
        </div>
        <div className='hero'>
            <div className='container'>

                {/* Left Side */}
                <div className='left'>
                    
                    <h1 className='pilote'>La création d'une entreprise en ligne via une plateforme dédiée</h1>
                    <p className='pilote'>simplifie considérablement le processus entrepreneurial. Grâce à des outils intuitifs et des guides pas à pas, 
                    les entrepreneurs peuvent rapidement accomplir les formalités administratives, choisir leur structure juridique, et lancer leur activité avec efficacité,
                     tout en bénéficiant d'un processus transparent et sécurisé.</p>
                   
                    <div className='input-container'>
                        
                    <a href='/contact'> <Link to='/contact'><button className='btn'>Commencer</button></Link></a>
                    </div>
                </div>


                {/* Right Side */}
                <div className='right'>
                    <div className='img-container'>
                        <img src={Ve} alt=''/>
                    </div>
                </div>
                
                
            </div>
            
        </div>
        <div className='container'>







</div>
        </div>
    </>
  )
}

export default Present