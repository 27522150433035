import React from 'react'
import './PreFor.css'
import { Link } from 'react-router-dom'

const PreFor = () => {
  return (
    
    <div name='support' class='support'>
    <div class='overlay'>
        
    </div>
    
    <div class='content'>
        <div class='text-container'>
            <h2 class='section-title pilote'>Investissez dans votre avenir dès aujourd'hui en vous inscrivant à nos formations</h2>
            <p class='section-subtitle pilote'>éducatives et professionnelles. Acquérez les compétences essentielles pour prospérer dans votre carrière et atteindre vos objectifs professionnels. </p>
        </div>
        <div class='card-grid'>
            <div class='card pilote'>
                <div class='card-content'>
                    <h3 class='card-title pilote'>Formation Comptabilité</h3>
                    <div className='formation'>
                    <h5 className='pilote'> - Conception et Création d'un Entreprise </h5>
                    </div>
                    <div className='formation'>
                    <h5 className='pilote'> - Comptabilité </h5>
                    </div>
                    <div className='formation'>
                    <h5 className='pilote'> - Ressources Humains</h5>
                    </div>
                    <div className='formation'>
                    <h5 className='pilote'> - Comptabilité Générale</h5>
                    </div>
                    <div className='formation'>
                    <h5 className='pilote'> - Comptabilité Avancé</h5> 
                    </div>
                </div>  
            </div>  
            <div class='card'>
            <div class='card pilote'>
                <div class='card-content'>
                    <h3 class='card-title pilote'>Formation Juridique</h3>
                    <div className='formation'>
                    <h5 className='pilote'> - Droit des sociétés </h5>
                    </div>
                    <div className='formation'>
                    <h5 className='pilote'> - Droit commercial </h5>
                    </div>
                    <div className='formation'>
                    <h5 className='pilote'> - Droit du travail et de la Sécurité Sociale</h5>
                    </div>
                    <div className='formation'>
                    <h5 className='pilote'> - Droit de change</h5>
                    </div>
                    <div className='formation'>
                    <h5 className='pilote'> - Tenue des registres règlementaire</h5>
                    
                    </div>
                    <div className='input-container'>
                        
                    <a href='/contact'> <Link to='/contact'><button className='btn'>Commencer</button></Link></a>
                    </div>
                </div>  
            </div>  
        </div> 
        <div class='card'>
            <div class='card pilote'>
                <div class='card-content'>
                    <h3 class='card-title pilote'>Formation Informatique</h3>
                    <div className='formation'>
                    <h5 className='pilote'> - Testeur Logiciel (ISTQB) </h5>
                    </div>
                    <div className='formation'>
                    <h5 className='pilote'> - Marketing Digital </h5>
                    </div>
                    <div className='formation'>
                    <h5 className='pilote'> - Development web</h5>
                    </div>
                    <div className='formation'>
                    <h5 className='pilote'> - Éxcel Avancé</h5>
                    </div>
                    <div className='formation'>
                    <h5 className='pilote'> - Graphic Design</h5>
                    </div>
                    <div className='input-container'>
                        
                    <a href='/contact'> <Link to='/contact'><button className='btn'>Commencer</button></Link></a>
                    </div>
                </div>  
            </div>  
        </div> 
        </div> 
      
    </div>
    
</div>
    
  )
}

export default PreFor
